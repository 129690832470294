<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="help-section">
        <div class="col-12 col-md-6">
          <h2>¿Necesitas ayuda?</h2>
          <p class="d-md-none">Consulta nuestras <a href="/pre-aprobado/preguntas-frecuentes" class="link-faq">Preguntas Frecuentes</a></p>
          <p class="d-md-none contact-text">Contáctanos</p>
          
          <div class="contact-info">
            <div class="contact-item">
              <img src="@/assets/img/phone-icon-white.svg" alt="Mail" class="mail-icon" />
              <span>952 864 645</span>
            </div>
            <div class="contact-item">
              <img src="@/assets/img/mail-icon-white.svg" alt="Mail" class="mail-icon" />
              <span>info@ibancredit.com</span>
            </div>
          </div>
          <p class="d-none d-md-block contact-text">Consulta nuestras <a href="/pre-aprobado/preguntas-frecuentes" class="link-faq">Preguntas Frecuentes</a></p>
        </div>

        <div class="d-none d-md-block col-md-6 ">
          <p class="text-right">Lunes a Viernes de 9:00 a 20:00</p>
          <p class="text-right">Sábados de 10:00 a 14:00</p>
        </div>
      </div>

      <div class="social-section">
        <img src="@/assets/img/logo_linkedin.png" alt="Ibancar" class="d-md-none linkedin-logo " />
        <img src="@/assets/img/logo_ibancredit_white.svg" alt="Ibancar Credit" class="d-none d-md-block ibancredit-logo" />
        <img class="rgpd-logo" src="@/assets/img/rgpd_small_webp.webp" alt="RGPD" />
        
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © Ibancredit 2025</p>
    </div>
  </footer>
</template>

<style scoped lang="scss">


@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "@/assets/styles/variables.scss";


.footer {
  background-color: $blue;
  color: white;
}

.footer-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.link-faq{
  color: white;
  text-decoration: underline;
  font-weight: 600;
}

.help-section {
  h2 {
    font-size: 0.95rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  p {
    margin: 0.35rem 0;
    font-size: 0.70rem;
    font-weight: 600;
  }


}

.contact-info {
  margin-top: 1rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  
  img {
    width: 12px;
    height: 12px;
    color: white;
    
  }
  span {
    font-size: 0.70rem;
    font-weight: 700;
  }
}

.social-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  align-items: flex-end;

  .linkedin-logo {
    width: 50px;
  }

  .rgpd-logo {
    width: 50px;
  }
}

.copyright {
  background-color: #000;
  text-align: center;
  padding: 0.5rem;
  
  p {
    margin: 0;
    font-size: 0.875rem;
  }
}

@include media-breakpoint-up(md) {

  .footer-content {
    flex-direction: column;
    padding: 2rem 5rem;
  }

  .help-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    
    h2 {
      margin-bottom: 0;
    }

    .contact-info {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 0.5rem;
    }

    .contact-text {
      margin: 0;
    }
  }

  .social-section {
    padding: 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid white;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;

    .rgpd-logo {
      width: 80px;
    }

    .ibancredit-logo {
      width: 200px;
    }
  }
}

@include media-breakpoint-up(lg) {

  .footer-content {
    flex-direction: column;
    padding: 3rem 6rem;
  }

  .help-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    p{
      font-size: 1.2rem;
    }
    
    h2 {
      margin-bottom: 0;
      font-size: 1.9rem;
    }

    .contact-info {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 0.5rem;

      .contact-item {
        img {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 1.2rem;
        }
      }
    }

    .contact-text {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  .social-section {
    padding: 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.272);
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;

    .rgpd-logo {
      width: 100px;
    }

    .ibancredit-logo {
      width: 200px;
    }
  }
}
</style>
