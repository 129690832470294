import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Section1 from '@/components/Home/Section1.vue'
import categories from './categories';
import redirectRoutes from './redirect'

// import rutasBlog from './rutasblog.js'

const routes = [{
        path: '/',
        name: 'Crédito con tu Coche como Aval',
        hash: '#tabla',
        component: Home,
        params: {
            title: 'Titulo home',
            aff: ''
        },
        meta: {
            breadcrumb: [{
                name: 'Inicio'
            }]
        },
        children: [
            {
                path:'',
                components: {
                    default: Section1,
                    formulario: Section1
                }
            },
        ]
    },
    {
        path: '/home/',
        name: 'Home',
        hash: '#tabla',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
        path: '/credito-coche',
        name: 'CreditoCoche',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/CreditoCoche.vue'),
    },
    {
        path: '/como-funciona',
        name: 'ComoFunciona',
        hash: '#documentacion',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "comofunciona" */ '../views/ComoFunciona.vue')
    },
    {
        path: '/como-funciona/escaner',
        name: 'Escaner',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "comofunciona" */ '../views/ComoFunciona.vue')
    },
    {
        path: '/quienes-somos',
        name: 'Quienes Somos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "quienessomos" */ '../views/QuienesSomos.vue'),
        meta: { transition: 'fade' }
    },
    {
        path: '/opiniones-clientes',
        name: 'Opiniones Clientes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "opinionesclientes" */ '../views/OpinionesClientes.vue'),
        meta: { transition: 'fade' }
    },
    // {
    //     path: '/blog',
    //     name: 'Blog',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "blog" */ '../views/Blog.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { name: 'Inicio', link: '/' },
    //             { name: 'Blog' }
    //         ]
    //     }
    // },
    {
        path: '/contacto',
        name: 'Contacto',
        hash: ['#contacta', '#enviarMensaje'],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "contacto" */ '../views/Contacto.vue')
    },
    {
        path: '/aviso-legal',
        name: 'AvisoLegal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "avisolegal" */ '../components/AvisoLegal.vue')
    },
    {
        path: '/reclamar',
        name: 'Reclamaciones',
        meta: {
            sitemap: { ignoreRoute: true }
        },
        component: () =>
            import ( /* webpackChunkName: "reclamaciones" */ '../views/Reclamaciones.vue')
    },
    {
        path: '/politica-privacidad',
        name: 'Privacidad',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "privacidad" */ '../components/Privacidad.vue')
    },
    {
        path: '/politica-cookies',
        name: 'Cookies',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "cookies" */ '../components/Cookies.vue')
    },
    {
        path: '/grupper-platform',
        name: 'Grupper',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "grupper" */ '../components/GrupperPlatform.vue')
    },
    {
        path: '/solicitud/coche/:codigo?',
        name: 'Fallo Matrícula',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada" */ '../components/NuevoFormulario/FalloAPI.vue')
    },
    {
        path: '/solicitud/denegada/:codigo?',
        name: 'Solicitud cancelada',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada" */ '../components/Formulario/SolicitudDenegada.vue')
    },

    {
        path: '/formulario/step1',
        name: 'Paso 1',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep1.vue')
    },
    {
        path: '/formulario/step1a',
        name: 'Paso 2',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep1A.vue')
    },
    {
        path: '/formulario/step1b',
        name: 'Paso 3',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep1B.vue')
    },
    {
        path: '/formulario/step1c',
        name: 'Paso 3B',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep1C.vue')
    },
    {
        path: '/formulario/step2',
        name: 'Paso 4',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep2.vue')
    },
    {
        path: '/formulario/step2a',
        name: 'Paso 5',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep2A.vue')
    },
    {
        path: '/formulario/step3',
        name: 'Paso 6',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStep3.vue')
    },
    {
        path: '/formulario/:codigo?',
        name: 'Formulario',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Documentacion" */ '../components/NuevoFormulario/NuevoFormularioSave.vue')
    },
    {
        path: '/formulario/documentacion',
        name: 'documentacion',
        hash: '#documentacionNecesaria',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "Documentacion" */ '../components/NuevoFormulario/NuevoSectionDocumentacion.vue')
    },
    {
        path: '/formulario/confirmacion',
        name: 'confirmacion',
        hash: '#newStepOk',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/NuevoFormulario/NewStepConfirm.vue')
    },
    {
        path:'/formulario/refuse/:refuse_message',
        name:'refuse',
        props: {refuse_message: true},
        component: () =>
            import ( /* webpackChunkName: "Documentacion" */ '../components/NuevoFormulario/Refuse.vue')
    },
    {
        path: '/prestamo-iniciar-negocio',
        name: 'Prestamo Iniciar Negocio',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_por_tu_coche" */ '../views/landings/PrestamoIniciarNegocio.vue')
    },
    {
        path: '/prestamo-reformar-vivienda',
        name: 'Prestamo Reformar Vivienda',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_por_tu_coche" */ '../views/landings/PrestamoReformarVivienda.vue')
    },
    {
        path: '/prestamo-reunificar-deudas',
        name: 'Prestamo Reunificar Deudas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_por_tu_coche" */ '../views/landings/PrestamoReunificarDeudas.vue')
    },
    {
        path: '/prestamo-personal',
        name: 'Prestamo Personal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_por_tu_coche" */ '../views/landings/PrestamoPersonal.vue')
    },
    {
        path: '/prestamo-por-tu-coche',
        name: 'Prestamo Rapido',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_por_tu_coche" */ '../views/PrestamoRapido.vue')
    },
    {
        path: '/prestamo-plazos-rapidos',
        name: 'Prestamo Plazos Rapidos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_plazos_rapidos" */ '../views/landings/PrestamoPlazosRapido.vue')
    },
    {
        path: '/credito-por-tu-coche',
        name: 'Credito por tu coche',
        redirect: {name: 'CreditoCoche'}
    },
    {
        path: '/dinero-instante-asnef',
        name: 'dinero al instante con asnef',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: {name: 'Dinero rapido con tu coche'}
    },
    {
        path: '/micro-prestamos-asnef',
        name: 'micro prestamos asnef',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "micro_prestamos_asnef" */ '../views/landings/MicroPrestamosAsnef.vue')
    },
    {
        path: '/prestamo-rapido',
        name: 'préstamo rápido',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "préstamo_rápido" */ '../views/landings/PrestamoRapido.vue')
    },
    {
        path: '/minicreditos',
        name: 'minicreditos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "minicredito" */ '../views/landings/MiniCreditos.vue')
    },
    {
        path: '/miniprestamos',
        name: 'miniprestamos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "minicredito" */ '../views/landings/MiniPrestamoRapido.vue')
    },
    {
        path: '/prestamos-bodas',
        name: 'prestamos boda',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_asnef" */ '../views/landings/PrestamosBoda.vue')
    },
    {
        path: '/empenar-coche',
        name: 'empenar coche',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "empenar_coche" */ '../views/landings/EmpenarCoche.vue')
    },
    {
        path: '/empenar-coche-malaga',
        name: 'empenar tu coche malaga',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheMalaga.vue')
    },
    {
        path: '/empenar-coche-madrid',
        name: 'empenar tu coche madrid',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheMadrid.vue')
    },
    {
        path: '/empenar-coche-murcia',
        name: 'empenar tu coche murcia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheMurcia.vue')
    },
  {
    path: '/empenar-coche-tenerife',
        name: 'empenar tu coche tenerife',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheTenerife.vue')
  },
  {
    path: '/empenar-coche-barcelona',
        name: 'empenar tu coche barcelona',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheBarcelona.vue')
  },
  {
    path: '/empenar-coche-girona',
        name: 'empenar tu coche girona',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheGirona.vue')
  },
  {
    path: '/empenar-coche-madrid',
        name: 'empenar tu coche madrid',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheMadrid.vue')
  },
  {
    path: '/empenar-coche-mas-diez-anos',
        name: 'empenar tu coche mas diez años',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheMasDiezAnos.vue')
  },

  {
    path: '/empenar-coche-sevilla',
        name: 'empenar tu coche sevilla',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheSevilla.vue')
  },
  {
    path: '/empenar-coche-valencia',
        name: 'empenar tu coche valencia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheValencia.vue')
  },
  {
    path: '/empenar-coche-alicante',
        name: 'empenar tu coche alicante',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheAlicante.vue')
  },
  {
    path: '/empenar-coche-zaragoza',
        name: 'empenar tu coche zaragoza',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheZaragoza.vue')
  },
  {
    path: '/empenar-coche-granada',
        name: 'empenar tu coche granada',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheGranada.vue')
  },
  {
    path: '/empenar-coche-las-palmas',
        name: 'empenar tu coche las palmas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "empenar_coche_malaga" */ '../views/landings/EmpenarCocheLasPalmas.vue')
  },
  {
    path: '/empena-coche-sin-entregarlo',
        name: 'empeñar coche sin entregarlo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: {name: 'empenar coche'}
    },
    {
        path: '/prestamo-rapido-10000-euros',
        name: 'prestamo rapido 10000 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoRapido10000Euros.vue')
    },
    {
        path: '/prestamo-rapido-1500-euros',
        name: 'prestamo rapido 1500 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoRapido1500Euros.vue')
    },
    {
        path: '/prestamo-rapido-2000-euros',
        name: 'prestamo rapido 2000 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoRapido2000Euros.vue')
    },
    {
        path: '/prestamo-rapido-3000-euros',
        name: 'prestamo rapido 3000 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoRapido3000Euros.vue')
    },
    {
        path: '/prestamo-rapido-6000-euros',
        name: 'prestamo rapido 6000 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoRapido6000Euros.vue')
    },
    {
        path: '/prestamo-obras',
        name: 'prestamo obras',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoObras.vue')
    },
    {
        path: '/prestamo-estudios',
        name: 'prestamo estudios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoEstudios.vue')
    },
    {
        path: '/prestamo-emprendedores',
        name: 'prestamo emprendedores',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoEmprendedores.vue')
    },
    {
        path: '/prestamo-garaje',
        name: 'prestamo garaje',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoGaraje.vue')
    },
    {
        path: '/prestamo-hacienda',
        name: 'prestamo hacienda',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoHacienda.vue')
    },
    {
        path: '/prestamo-jubilados',
        name: 'prestamo jubilados',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoJubilados.vue')
    },
    {
        path: '/prestamo-docentes',
        name: 'prestamo docentes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoDocentes.vue')
    },
    {
        path: '/prestamo-dentista',
        name: 'prestamo dentista',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoDentista.vue')
    },
    {
        path: '/prestamo-autonomos',
        name: 'prestamo autonomos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoAutonomos.vue')
    },
    {
        path: '/prestamo-placas-solares',
        name: 'prestamo placas solares',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoPlacasSolares.vue')
    },
    {
        path: '/prestamo-piso',
        name: 'prestamo piso',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoPiso.vue')
    },
    {
        path: '/prestamo-terreno',
        name: 'prestamo terreno',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamo_rapido_10000_euros" */ '../views/landings/PrestamoTerreno.vue')
    },
    {
        path: '/dinero-rapido-con-tu-coche',
        name: 'Dinero rapido con tu coche',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "dinero_rapido_con_tu_coche" */ '../views/DineroRapidoConTuCoche.vue')
    },
    {
        path: '/dinero-rapido-valencia',
        name: 'Dinero rapido Valencia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "dinero_rapido_con_tu_coche" */ '../views/DineroRapidoValencia.vue')
    },
    {
        path: '/credito-por-tu-coche-en-barcelona',
        name: 'Crédito por tu coche en Barcelona',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_por_tu_coche_en_barcelona" */ '../views/CreditoPorTuCocheEnBarcelona.vue')
    },
    {
        path: '/credito-por-tu-coche-en-madrid',
        name: 'Crédito por tu coche en Madrid',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_por_tu_coche_en_madrid" */ '../views/CreditoPorTuCocheEnMadrid.vue')
    },
    {
        path: '/credito-por-tu-coche-en-sevilla',
        name: 'Crédito por tu coche en Sevilla',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_por_tu_coche_en_sevilla" */ '../views/CreditoPorTuCocheEnSevilla.vue')
    },
    {
        path: '/credito-por-tu-coche-en-valencia',
        name: 'Crédito por tu coche en Valencia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_por_tu_coche_en_valencia" */ '../views/CreditoPorTuCocheEnValencia.vue')
    },
    {
        path: '/credito-aval-coche-las-palmas',
        name: 'Crédito aval coche Las Palmas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_aval_coche_las_palmas" */ '../views/CreditoAvalCocheLasPalmas.vue')
    },
   
    {
        path: '/prestamos-con-aval-de-coche-sin-cambiar-de-titular',
        name: 'Préstamos con aval de coche sin cambiar de titular',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_aval_de_coche_sin_cambiar_de_titular" */ '../views/PrestamosConAvalDeCocheSinCambiarDeTitular.vue')
    },
    {
        path: '/prestamos-con-aval-del-coche-de-mas-de-10-anos',
        name: 'Préstamos con aval del coche de mas de 10 años',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_aval_de_coche_de_mas_de_10_anos" */ '../views/PrestamosConAvalDelCocheDeMasDe10Anos.vue')
    },
    {
        path: '/prestamos-por-tu-coche-alicante',
        name: 'Préstamos por tu coche Alicante',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_por_tu_coche_alicante" */ '../views/PrestamosPorTuCocheAlicante.vue')
    },
    {
        path: '/prestamos-aval-coche-valencia',
        name: 'Préstamos aval coche Valencia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_aval_coche_valencia" */ '../views/PréstamosAvalCocheValencia.vue')
    },
    {
        path: '/prestamos-5000-euros',
        name: 'Préstamos 5000 euros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prestamos_aval_coche_valencia" */ '../views/landings/Prestamos5000Euros.vue')
    },
    {
        path: '/credito-aval-coche-con-asnef',
        name: 'Crédito aval coche con Asnef',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_aval_coche_con_asnef" */ '../views/CreditoAvalCocheConAsnef.vue')
    },
    {
        path: '/credito-con-aval',
        name: 'Crédito con aval',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: {name: 'CreditoCoche'}

    },
    {
        path: '/investor',
        name: 'Investor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "investor" */ '../components/Investor.vue')
    },
    {
        path: '/formulario-:codigo?',
        redirect: { name: "Formulario" }
    },
    {
        path: '/pagos/:tokenPago',
        redirect: to => ({
            path: "/php/pagos.php",
            query: { 'code': to.params.tokenPago },
        }),
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    },
    {
        path: '/pre-aprobado',
        name: 'pre-aprobado',
        params: {
            token: null
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado" */ '../views/PreAprobado.vue')
    },
    {
        path: '/pre-aprobado/paso-1',
        name: 'pre-aprobado-paso-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/paso-1" */ '../views/PreAprobado.vue')

    },
    {
        path: '/pre-aprobado/paso-2',
        name: 'pre-aprobado-paso-2',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/paso-2" */ '../views/PreAprobado.vue')
    },
    {
        path: '/pre-aprobado/escoger-banco',
        name: 'pre-aprobado-escoger-banco',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/escoger-banco" */ '../views/PreAprobado.vue')
    },
    {
        path: '/pre-aprobado/solicitud-expirada',
        name: 'pre-aprobado-solicitud-expirada',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/solicitud-expirada" */ '../views/PreAprobado.vue')
    },
    {
        path: '/pre-aprobado/preguntas-frecuentes',
        name: 'pre-aprobado-preguntas-frecuentes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/preguntas-frecuentes" */ '../views/PreAprobado.vue')
    },
    {
        path: '/pre-aprobado/gracias',
        name: 'pre-aprobado-gracias',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "pre-aprobado/gracias" */ '../views/PreAprobado.vue')
    },
    {
        path: '/callback-widget/:codigo?',
        name: 'AfterBankWidget',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "widget" */ '../views/widget/AfterBankWidget.vue')
    },

]
 
routes.push(...categories.prestamoAsnef)
routes.push(...redirectRoutes);


// rutasBlog.forEach((i)=> {
//   routes.unshift(i);
// })

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const position = {};
        if (to.hash) {
            switch (to.hash) {
                case '#packServicios':
                    position.offset = { y: 250 }
                    break;
                case '#startBlog':
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    }
                case '#tabla':
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    }
                default:
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    };
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

// router.beforeEach(
//     (to, next) => {
//         let documentTitle = `${to.name} | ${process.env.VUE_APP_TITLE}`
//         if (to.params.title) {
//             documentTitle = `${to.params.title} | ${process.env.VUE_APP_TITLE}`
//         }
//         document.title = documentTitle
//         next
//     }
// );


export default router