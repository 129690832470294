<template  >
  <div :class='{"d-none" : preload , "d-none d-md-none d-sm-none d-md-block d-lg-block d-xl-block": !preload}' class="bg-azul " id="footer_menu">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div id="logotipo">
              <img width="228" height="59" src="@/assets/img/logo_ibancar.svg" alt="Ibancar" />
            </div>
            <div class="card-body">
              <p class="card-text"></p>
              <div id="desktop">
                <img width="134" height="67" id="desktop" src="@/assets/img/rgpd_small_webp.webp" alt="Ibancar" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12 text-center">
          <div class="card">
            <div id="llamar" class="card-body">
              <a class="btn btn-footer" href="tel:+34952864645">
                <div class="d-flex justify-content-center">
                  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="icon" d="M3.62 8.03393C5.06 10.8691 7.38 13.1933 10.21 14.6359L12.41 12.4319C12.69 12.1514 13.08 12.0713 13.43 12.1815C14.55 12.5521 15.75 12.7525 17 12.7525C17.2652 12.7525 17.5196 12.8581 17.7071 13.0459C17.8946 13.2338 18 13.4886 18 13.7543V17.2607C18 17.5264 17.8946 17.7812 17.7071 17.9691C17.5196 18.157 17.2652 18.2625 17 18.2625C12.4913 18.2625 8.1673 16.4682 4.97918 13.2743C1.79107 10.0803 0 5.74845 0 1.23156C0 0.965858 0.105357 0.711041 0.292893 0.523163C0.48043 0.335285 0.734784 0.229736 1 0.229736H4.5C4.76522 0.229736 5.01957 0.335285 5.20711 0.523163C5.39464 0.711041 5.5 0.965858 5.5 1.23156C5.5 2.48383 5.7 3.68602 6.07 4.80806C6.18 5.1587 6.1 5.54941 5.82 5.82992L3.62 8.03393Z" fill="white" />
                  </svg>
                  <span class="pl-2">952 864 645</span>
                </div>
              </a>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <a class="btn btn-footer" href="mailto:info@ibancar.com">
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="icon" d="M2.2 0.404785H19.8C20.3835 0.404785 20.9431 0.64227 21.3556 1.065C21.7682 1.48772 22 2.06106 22 2.65888V16.1835C22 16.7813 21.7682 17.3546 21.3556 17.7774C20.9431 18.2001 20.3835 18.4376 19.8 18.4376H2.2C0.979 18.4376 0 17.4232 0 16.1835V2.65888C0 1.40786 0.979 0.404785 2.2 0.404785ZM11 8.29413L19.8 2.65888H2.2L11 8.29413ZM2.2 16.1835H19.8V5.32999L11 10.954L2.2 5.32999V16.1835Z" fill="white" />
                </svg>
                <span class="pl-2">info@ibancar.com</span>
              </a>
            </div>
          </div>
          <div class="card text-center pt-2">
            <div class="card-body">
              <div class="d-flex flex-row justify-content-center">
                <div class="pr-4">
                  <a id="btn-facebook" href="https://www.facebook.com/ibancarspain" target="_blank" rel="nofollow noopener" aria-label="Facebook">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="icon" d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 35.9789 8.77641 45.908 20.25 47.7084V30.9375H14.1562V24H20.25V18.7125C20.25 12.6975 23.8331 9.375 29.3152 9.375C31.9402 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6613C28.68 15.75 27.75 17.6002 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7084C39.2236 45.908 48 35.9789 48 24Z" fill="white" />
                    </svg>
                  </a>
                </div>
                <div class="pl-4">
                  <a id="btn-linkedin" href="https://www.linkedin.com/company/ibancar-world/mycompany/" target="_blank" rel="nofollow noopener" aria-label="Linkedin">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="icon" d="M44.4469 0H3.54375C1.58437 0 0 1.54688 0 3.45938V44.5312C0 46.4437 1.58437 48 3.54375 48H44.4469C46.4062 48 48 46.4438 48 44.5406V3.45938C48 1.54688 46.4062 0 44.4469 0ZM14.2406 40.9031H7.11563V17.9906H14.2406V40.9031ZM10.6781 14.8688C8.39062 14.8688 6.54375 13.0219 6.54375 10.7437C6.54375 8.46562 8.39062 6.61875 10.6781 6.61875C12.9563 6.61875 14.8031 8.46562 14.8031 10.7437C14.8031 13.0125 12.9563 14.8688 10.6781 14.8688ZM40.9031 40.9031H33.7875V29.7656C33.7875 27.1125 33.7406 23.6906 30.0844 23.6906C26.3812 23.6906 25.8187 26.5875 25.8187 29.5781V40.9031H18.7125V17.9906H25.5375V21.1219H25.6312C26.5781 19.3219 28.9031 17.4188 32.3625 17.4188C39.5719 17.4188 40.9031 22.1625 40.9031 28.3313V40.9031Z" fill="white" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
          <div id="horario" class="card text-center">
            <div class="card-header">
              <p class="card-text text-center">Horario</p>
              <p class="card-text text-center pb-2"><strong>Lunes a Viernes</strong> de <strong>9:00</strong> a <strong>20:00</strong></p>
              <p class="card-text text-center pb-2"><strong>Sábados</strong> de <strong>10:00</strong> a <strong>14:00</strong></p>
            </div>
            <div class="card-body pt-3">
              <p class="card-text text-center pb-2"><router-link to="/"> Inicio</router-link> | <router-link to="/aviso-legal" target="_blank">Aviso Legal</router-link> | <router-link :to="`${publicPath}` + 'sitemap.xml'" target="_blank">SiteMap</router-link></p>
              <p class="card-text text-center pb-2"><router-link to="/politica-privacidad" target="_blank">Política de Privacidad</router-link> | <router-link to="/politica-cookies" target="_blank"> Política de Cookies.</router-link></p>
            </div>
            <div class="card-footer text-muted pt-3">
              <p><a href="https://p2p.ibancar.com/" target="_blank">Ibancar P2P transparency site</a></p>
              <p><router-link to="/grupper-platform">information for Grupeer Investors</router-link></p>
              <div id="mobile" class="d-flex flex-row justify-content-center pt-3">
                <img width="134" height="67" id="mobile" src="@/assets/img/rgpd_small_webp.webp" alt="Ibancar" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body t12">
                <p class="card-text">Oferta de financiación sujeta a aprobación de Ibancar World SL en España. Préstamos al consumo entre 300€ y 6000€ y duración entre 12 y 36 meses. El TAE podrá variar en función de la cantidad a prestar. Variación de la TAE 16.06% (min.) y 16.07% (máx.) y TIN 15% (mín.) . Importe calculado para financiaciones realizadas el día 1 del mes, y primer vencimiento el 1 del mes siguiente; en caso de que las fechas difieran, el importe total puede sufrir pequeñas variaciones. Sistema de amortización francés.</p>
                <p class="card-text">*Para un importe solicitado de 1000€ en 12 cuotas mensuales de 90.26€ (amortización francesa). TAE: 16.07%, TIN: 15.00%. Importe de los intereses: 83€. Coste total del préstamo: 1083€.</p>
                <p class="card-text">*Para un importe solicitado de 6000€ en 36 cuotas mensuales de 207.99€ (amortización francesa). TAE: 16.07%, TIN: 15.00%. Importe de los intereses: 1488€. Coste total del préstamo: 7488€.</p>
                <p class="card-text">*Coste de servicios no incluidos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-gray d-none d-sm-none d-md-block d-lg-block d-xl-block">
    <div class="row align-items-center justify-content-center pt-3">
      <p class="copyright">Copyright © Ibancar {{ new Date().getFullYear() }}</p>
      <img rel="preconnect" alt="metricool_pixel" src="https://tracker.metricool.com/c3po.jpg?hash=f9cab7082580b380cabbc39b57a755" />
    </div>
  </div>
  <FooterFormulario />
</template>

<script>
import FooterFormulario from "./FooterFormulario.vue";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "Foot",
  components: {FooterFormulario},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  store,
  computed: mapState({
    preload: (state) => state.preload,
  }),
};
</script>

<style scoped lang="scss">
.card {
  background-color: transparent;
  border: none;
  color: #fff;
}
.card-text {
  text-align: left;
  color: #fff;
}

.bg-gray {
  color: #fff;
  font-size: 10px;
  background: linear-gradient(0deg, #18333d, #18333d), #003159;
}
#horario {
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }
  a:active {
    color: #fff;
  }
}

.btn-footer {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #004680;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover .icon {
  fill: #004680;
}

#btn-linkedin:hover .icon {
  fill: #0a66c2;
}
#btn-facebook:hover .icon {
  fill: #1877f2;
}
.t12 {
  font-size: 12px;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-down(md) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
  }
  #logotipo {
    text-align: center;
  }
  #horario {
    padding-top: 25px;
    .card-header {
      background: none;
      border: none;
      padding: 0;
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #fff;
    }
  }
  #desktop {
    display: none;
  }
  .copyright {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-between(lg, xl) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 3rem;
    padding-left: 5.5rem;
    padding-right: 4rem;
    padding-bottom: 3rem;
  }
  #logotipo {
    text-align: left;
  }
  #horario {
    .card-header {
      background: none;
      border: none;
      padding: 0;
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #fff;
    }
    #mobile {
      display: none;
    }
  }
}
</style>
