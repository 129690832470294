<template>
  <div id="navigation_menu">
    <!-- OPCIONES DE MENU DESKTOP  -->
    <nav id="desktop"  v-if='!preload' class="p-0" role="navigation">
      <!-- NAVBAR -->
      <div class="d-flex flex-row justify-content-center align-items-center pt-3 pb-3 ">
        <!-- ZONA LOGO -->
        <div id="logoMenu" class="d-flex justify-content-center">
            <img  id="ibancarLogo" class="img-fluid" src="@/assets/img/logo_ibancredit.svg" alt="Ibancar" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
window.addEventListener("scroll", () => {
  let navbar = document.getElementById("desktop");
  const scrollY = window.scrollY;
  // const screen_width = window.screen.width;
  if (scrollY > 0) {
    navbar.className = "transition_forward";
  } else {
    navbar.className = "transition_backward";
  }
});
import store from "@/store";
import { mapState } from "vuex";
export default {
  name: "NavBarPreAprobado",
  emits: ["clicked"],
  el: "#navigation_menu",
  methods: {
    triggered(e) {
      this.$emit("clicked", e.target);
    },
    close_offcanvas(route) {
      let mobile_canvas = document.querySelector(".mobile-offcanvas.show");
      this.$parent.darken_screen(false);
      mobile_canvas ? mobile_canvas.classList.remove("show") : "";
      document.body.classList.remove("offcanvas-active");
      route ? this.$router.push({ path: route }) : null;
    },
  },
  store,
  computed: mapState({
    preload: (state) => state.preload,
    afiliado: (state) => state.afiliado,
  }),
};
</script>

<style scoped lang="scss">

.transition_forward {
  padding: 0;
  transition: ease 0.3s;
  //animation-fill-mode: forwards;
  box-shadow: 0px 0px 10px 8px #0000002e;
}

.transition_backward {
  padding: 0%;
  transition: ease-out 0.3s;
  //animation-fill-mode: forwards;
}

nav{
  background: #fff;
}

#ibancarLogo {
  width: 73%;
}

// Clases específicas según tamaño de pantalla:

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "@/assets/styles/variables.scss";

// Pantallas muy pequeñas a pequeñas 0 a 575px

@include media-breakpoint-between(xs, sm) {
 
}
// Pantallas de 576 a 767px
@include media-breakpoint-between(sm, md) {
}

// Pantallas medianas a Grandes 767px a 991px
@include media-breakpoint-only(md) {
 
  #ibancarLogo {
    width: 50%;
  }
}

@include media-breakpoint-up(lg) {
  #ibancarLogo {
    width: 80%;
  }
}


</style>
